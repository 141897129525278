import React, { useState, useEffect } from "react";
import {
  Stack,
  Grid,
  Typography,
  Button,
  Divider,
  Box,
  Card,
  CardContent,
  CardMedia,
  ImageList,
  ImageListItem,
} from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import conferencistas_json from "../../info/conferencistas.json";
import LinkIcon from "@mui/icons-material/Link";
const helpers = require("../../helpers/helpers");
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const Inicio = () => {
  const [cambios, setCambios] = useState(0);
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const arr_expositores = helpers.chunkArray(
    conferencistas_json.conferencistas,
    4
  );
  const maxSteps = arr_expositores.length;

  useEffect(() => {}, [cambios]);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setCambios(cambios + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setCambios(cambios + 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
    setCambios(cambios + 1);
  };
  return (
    <Stack style={{ width: "100%" }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ backgroundColor: "#292F3B" }}
      >
        <Grid xs={12} md={12} lg={6} p={10}>
          <Stack spacing={3}>
            <Typography style={{ color: "white", fontSize: "1em" }} variant="p">
              <strong>JUNIO 11 y 12, 2024</strong>&nbsp;|&nbsp;GUATEMALA
              <br />
              <strong>JULIO 28, 2024</strong>&nbsp;|&nbsp;EL SALVADOR
              <br />
              <strong>AGOSTO 25, 2024</strong>&nbsp;|&nbsp;HONDURAS
              <br />              
            </Typography>
            <Typography style={{ color: "white" }} variant="h5">
              EN DONDE QUIENES TOMAN LAS DECISIONES VEN EL FUTURO DE LA BANCA
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={12} md={12} lg={6} style={{ textAlign: "center" }}>
          <video playsInline autoPlay muted loop style={{ width: "100%" }}>
            <source
              src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/b039baa1-37de-49dc-92f4-a8d540f2c5b3.mp4"
              type="video/mp4"
            />
          </video>
        </Grid>
      </Grid>
      <Stack
        spacing={4}
        style={{ backgroundColor: "#C5C5C5" }}
        alignItems="center"
      >
        <Box p={5}>
          <Typography
            variant="h3"
            style={{ textAlign: "center", color: "#004C6C" }}
          >
            Lo que puedes esperar de Coretic y Coreseb <br />
            en menos de 3 minutos...
          </Typography>
        </Box>
        <Divider
          style={{ backgroundColor: "#004C6C", height: "5px", width: "90%" }}
        />
        <video controls style={{ width: "50%" }}>
          <source
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/VIDEO-2023-01-27-16-31-15.mp4"
            type="video/mp4"
          />
        </video>
      </Stack>
      <Stack spacing={4} alignItems="center">
        <Box p={5}>
          <Typography
            variant="h3"
            style={{ textAlign: "center", color: "#004C6C" }}
          >
            El Evento de Tecnología Líder para el Sector Bancario y Financiero
            de Centroamérica
          </Typography>
        </Box>
        <Grid container justifyContent="center" p={3}>
          <Grid xs={12} md={12} lg={4} style={{ textAlign: "center" }}>
            <Stack
              spacing={2}
              alignItems="center"
              style={{ height: "100%" }}
              justifyContent="space-between"
            >
              <Divider
                style={{
                  backgroundColor: "#004C6C",
                  height: "5px",
                  width: "90%",
                }}
              />

              <Typography variant="h4">Conexiones significativas</Typography>
              <img
                src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/IMG_0008.jpg"
                style={{ width: "75%", borderRadius: "2em" }}
              />
              <Stack
                alignItems="center"
                style={{ height: "100%" }}
                justifyContent="space-between"
              >
                <Typography
                  variant="p"
                  style={{ textAlign: "justify", fontStyle: "italic" }}
                  p={6}
                >
                  Tu mejor opción para una audiencia de nivel ejecutivo.
                  <br />
                  <br />
                  Conoce a más de 200 profesionales que toman decisiones en la
                  región de Centroamérica.
                  <br />
                  <br />
                  Conecta con Ejecutivos y demás personal de las áreas de IT,
                  Canales Electrónicos, Soporte Tecnológico, Seguridad
                  Informática, Banca Moderna, Administración de Redes, así como
                  personal de las áreas de Operaciones, Finanzas, Proyectos,
                  Riesgos, Atención al Cliente, Negocios, entre otras, de las
                  Entidades Bancarias, Financieras, Cooperativas, Aseguradoras,
                  Casas de Bolsa, Universidades y empresas en general,
                  consultores de sistemas.
                </Typography>
                <Button>¿Quíen asistirá?</Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid xs={12} md={12} lg={4} style={{ textAlign: "center" }}>
            <Stack spacing={2} alignItems="center" style={{ height: "100%" }}>
              <Divider
                style={{
                  backgroundColor: "#004C6C",
                  height: "5px",
                  width: "90%",
                }}
              />

              <Typography variant="h4">
                Networking en calidad y cantidad
              </Typography>
              <img
                src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/IMG_9994+(1).jpg"
                style={{ width: "75%", borderRadius: "2em" }}
              />
              <Stack
                alignItems="center"
                style={{ height: "100%" }}
                justifyContent="space-between"
              >
                <Typography
                  variant="p"
                  style={{ textAlign: "justify", fontStyle: "italic" }}
                  p={6}
                >
                  Amplía tu red de networking de manera efectiva en Coretic -
                  Coreseb, el evento perfecto para conectar con personas que
                  pueden mover hacia adelante tu negocio.
                  <br />
                  <br />
                  En nuestros espacios agendados, podrás encontrar muchas
                  oportunidades para crear conexiones.
                  <br />
                  <br />
                  Refrigerios, Almuerzos y experiencias especiales de nuestros
                  patrocinadores.
                </Typography>
                <Button>Oportunidades de Networking</Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid xs={12} md={12} lg={4} style={{ textAlign: "center" }}>
            <Stack spacing={2} alignItems="center" style={{ height: "100%" }}>
              <Divider
                style={{
                  backgroundColor: "#004C6C",
                  height: "5px",
                  width: "90%",
                }}
              />

              <Typography variant="h4">
                Demos de productos a la vanguardia
              </Typography>
              <img
                src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/IMG_0002.jpg"
                style={{ width: "75%", borderRadius: "2em" }}
              />
              <Stack
                alignItems="center"
                style={{ height: "100%" }}
                justifyContent="space-between"
              >
                <Typography
                  variant="p"
                  style={{ textAlign: "justify", fontStyle: "italic" }}
                  p={6}
                >
                  Sin complicaciones, únicamente la tecnología más innovadora,
                  lista para llevarte al límite para competir.
                  <br />
                  <br />
                  <ul>
                    <li>Compañías y lanzamiento de productos.</li>
                    <li>
                      Tecnología para cualquier banco de cualquier dimensión.
                    </li>
                    <li>
                      Soluciones de software tal como pagos electrónicos, banca
                      digital y mucho más.
                    </li>
                  </ul>
                </Typography>
                <Button>Compañías presentes</Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      {/*<Stack
        spacing={4}
        alignItems="center"
        style={{ backgroundColor: "#C5C5C5" }}
        p={2}
      >
        <Typography variant="h4" style={{ color: "#004C6C" }} p={3}>
          Algunos de nuestros conferencistas
        </Typography>
        <Divider
          style={{
            backgroundColor: "#004C6C",
            height: "2px",
            width: "90%",
          }}
        />
       <Grid container justifyContent="center" alignItems="center">
          <Grid xs={12} md={12} lg={12}>
            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
              interval={5000}
            >
              {arr_expositores.map((actual, index) => (
                <Box
                  alignItems="center"
                  justifyContent="center"
                  key={index}
                  style={{ width: "100%" }}
                >
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="stretch"
                    >
                      {actual.map((speaker, indice) => (
                        <Grid
                          xs={6}
                          md={6}
                          lg={3}
                          item
                          key={`speaker-${indice}`}
                          style={{
                            display: "flex",
                            backgroundColor: "transparent",
                          }}
                          p={5}
                        >
                          <Card
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              backgroundColor: "transparent",
                            }}
                          >
                            <CardMedia
                              component="img"
                              style={{
                                width: "100%",
                                height: "140px",
                                objectFit: "contain",
                                alignSelf: "flex-start", // Align photo to the top
                              }}
                              image={speaker.foto}
                              alt="conferencista-cladit"
                            />

                            <CardContent
                              style={{
                                alignSelf: "flex-start",
                                textAlign: "center",
                                width: "100%",
                              }}
                            >
                              <Stack alignItems="center">
                                <img
                                  src={speaker.bandera}
                                  alt="bandera-speaker"
                                  style={{
                                    width: "25%",
                                    height: "35px",
                                    objectFit: "contain",
                                  }}
                                />
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="div"
                                >
                                  {speaker.nombre}
                                </Typography>
                                <Typography
                                  variant="body"
                                  color="text.secondary"
                                >
                                  {speaker.puesto}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {speaker.institucion}
                                </Typography>
                                <Typography
                                  variant="body3"
                                  color="text.secondary"
                                >
                                  {speaker.pais}
                                </Typography>
                              </Stack>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  ) : null}
                </Box>
              ))}
            </AutoPlaySwipeableViews>
          </Grid>
          <Grid
            xs={12}
            md={12}
            lg={12}
            style={{ width: "100%", backgroundColor: "transparent" }}
          >
            <MobileStepper
              style={{ width: "100%", backgroundColor: "transparent" }}
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                </Button>
              }
            />
          </Grid>
        </Grid>
      </Stack>*/}
      <Grid container alignItems="center" justifyContent="center" p={4}>
        <Grid xs={12} md={12} lg={6} style={{ textAlign: "center" }} p={2}>
          <Stack spacing={3}>
            <Typography variant="h4">Evento 100% Gratuito</Typography>
            <Divider
              style={{
                backgroundColor: "#004C6C",
                height: "5px",
                width: "100%",
              }}
            />
            <Typography
              variant="p"
              style={{ textAlign: "center", fontStyle: "italic" }}
            >
              Aprende sobre las últimas tendencias en tecnología y seguridad
              bancaria.
              <br />
              Encuentra oportunidades de negocio y conecta con otras personas
              del sector bancario y financiero.
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={12} md={12} lg={6} style={{ textAlign: "center" }} p={2}>
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/WhatsApp+Image+2024-04-12+at+08.40.49.jpeg"
            style={{ width: "100%" }}
            alt="img-stage"
          />
        </Grid>
      </Grid>
      <Stack spacing={2} style={{ backgroundColor: "#11122E" }} p={4}>
        <Typography
          variant="h4"
          style={{ textAlign: "center", color: "white" }}
        >
          Se visto, se escuchado
        </Typography>
        <Typography variant="p" style={{ textAlign: "center", color: "white" }}>
          Conecta con quienes toman las decisiones.
        </Typography>
        <Button
          startIcon={<LinkIcon />}
          style={{ color: "white" }}
          href="/sponsorship"
        >
          CLICK PARA Más información Sobre Patrocinios
        </Button>
        <Divider
          style={{
            backgroundColor: "white",
            height: "5px",
            width: "100%",
          }}
        />
        <ImageList cols={4}>
          <ImageListItem>
            <img
              src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/IMG_0010.jpg`}
              alt="STAND-img"
              loading="lazy"
            />
          </ImageListItem>
          <ImageListItem>
            <img
              src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/IMG_0016.jpg`}
              alt="STAND-img"
              loading="lazy"
            />
          </ImageListItem>
          <ImageListItem>
            <img
              src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/IMG_0014.jpg`}
              alt="STAND-img"
              loading="lazy"
            />
          </ImageListItem>
          <ImageListItem>
            <img
              src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/IMG_0013.jpg`}
              alt="STAND-img"
              loading="lazy"
            />
          </ImageListItem>
        </ImageList>
      </Stack>
    </Stack>
  );
};

export default Inicio;

/*

  <Stack
        spacing={5}
        style={{ backgroundColor: "#C5C5C5", color: "#004C6C" }}
        p={5}
      >
        <Typography variant="h4">Patrocinador Nivel Diamante</Typography>
        <Grid container alignItems="center" justifyContent="center">
          <Grid xs={12} md={12} lg={2} style={{ textAlign: "center" }} p={2}>
            <Card>
              <CardActionArea
                onClick={() => {
                  window.open("https://www.es.consulting/", "_blank");
                }}
              >
                <CardMedia
                  style={{
                    width: "100%",
                    height: "150px",
                    objectFit: "contain",
                    padding: "3%",
                  }}
                  component="img"
                  image="https://escuela-bancaria.s3.us-east-2.amazonaws.com/1709674793659-3%20ES.png"
                  alt="sponsor-img"
                />
              </CardActionArea>
            </Card>
          </Grid>
          <Grid xs={12} md={12} lg={2} style={{ textAlign: "center" }} p={2}>
            <Card>
              <CardActionArea
                onClick={() => {
                  window.open(
                    "https://www.cloudflare.com/es-es/lp/ppc/overview-x/?utm_source=google&utm_medium=cpc&utm_campaign=ao-fy-acq-latam_spa_casa-umbrella-ge-ge-prospecting-sch_g_generic_alpha&utm_content=Alpha_Brand_Umbrella_Core&utm_term=cloudflare&campaignid=71700000112174150&adgroupid=58700008470909005&creativeid=666168093577&&_bt=666168093577&_bk=cloudflare&_bm=b&_bn=g&_bg=150333947985&_placement=&_target=&_loc=9077184&_dv=c&awsearchcpc=1&gad_source=1&gclid=CjwKCAiAopuvBhBCEiwAm8jaMb8_7G2cm5fbBcQDTLeDFwLCb8fgrsWoMLmWpWpOAlBVkWk2Q3KmoxoCRFIQAvD_BwE&gclsrc=aw.ds",
                    "_blank"
                  );
                }}
              >
                <CardMedia
                  style={{
                    width: "100%",
                    height: "150px",
                    objectFit: "contain",
                    padding: "3%",
                  }}
                  component="img"
                  image="https://escuela-bancaria.s3.us-east-2.amazonaws.com/1709674858132-CLOUDFLARE.png"
                  alt="sponsor-img"
                />
              </CardActionArea>
            </Card>
          </Grid>
          <Grid xs={12} md={12} lg={2} style={{ textAlign: "center" }} p={2}>
            <Card>
              <CardActionArea
                onClick={() => {
                  window.open("https://www.bdgsa.net/", "_blank");
                }}
              >
                <CardMedia
                  style={{
                    width: "100%",
                    height: "150px",
                    objectFit: "contain",
                    padding: "3%",
                  }}
                  component="img"
                  image="https://escuela-bancaria.s3.us-east-2.amazonaws.com/LOGO-BDG.png"
                  alt="sponsor-img"
                />
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
        <Divider
          style={{
            backgroundColor: "#004C6C",
            height: "5px",
            width: "100%",
          }}
        />
        <Typography variant="h4">Patrocinador Nivel Oro</Typography>
        <Grid container alignItems="center" justifyContent="center">
          <Grid xs={12} md={12} lg={2} style={{ textAlign: "center" }} p={2}>
            <Card>
              <CardActionArea
                onClick={() => {
                  window.open(
                    "https://www.corporacionbi.com/gt/bancoindustrial/",
                    "_blank"
                  );
                }}
              >
                <CardMedia
                  style={{
                    width: "100%",
                    height: "150px",
                    objectFit: "contain",
                    padding: "3%",
                  }}
                  component="img"
                  image="https://escuela-bancaria.s3.us-east-2.amazonaws.com/1712251736833-2%20BI%20%282%29.png"
                  alt="sponsor-img"
                />
              </CardActionArea>
            </Card>
          </Grid>
          <Grid xs={12} md={12} lg={2} style={{ textAlign: "center" }} p={2}>
            <Card>
              <CardActionArea
                onClick={() => {
                  window.open("https://fluidattacks.com/es/", "_blank");
                }}
              >
                <CardMedia
                  style={{
                    width: "100%",
                    height: "150px",
                    objectFit: "contain",
                    padding: "3%",
                  }}
                  component="img"
                  image="https://escuela-bancaria.s3.us-east-2.amazonaws.com/1709674890938-3%20FLUID%20ATTACKS.png"
                  alt="sponsor-img"
                />
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
        <Divider
          style={{
            backgroundColor: "#004C6C",
            height: "5px",
            width: "100%",
          }}
        />
        <Typography variant="h4">Patrocinador Nivel Plata</Typography>
        <Grid container alignItems="center" justifyContent="center">
          <Grid xs={12} md={12} lg={2} style={{ textAlign: "center" }} p={2}>
            <Card>
              <CardActionArea
                onClick={() => {
                  window.open(
                    "https://www.inni.com.gt/?gclid=CjwKCAiAopuvBhBCEiwAm8jaMQWS4ac6o7uEC4ZAkvLQoquR_lHSZZXU7mEpbWxRHYk0HdA3yrNN-BoCflUQAvD_BwE",
                    "_blank"
                  );
                }}
              >
                <CardMedia
                  style={{
                    width: "100%",
                    height: "150px",
                    objectFit: "contain",
                    padding: "3%",
                  }}
                  component="img"
                  image="https://escuela-bancaria.s3.us-east-2.amazonaws.com/1709674974260-INNI.png"
                  alt="sponsor-img"
                />
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
        <Divider
          style={{
            backgroundColor: "#004C6C",
            height: "5px",
            width: "100%",
          }}
        />
      </Stack>
*/
