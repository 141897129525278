import React from "react";
import View from "../evento/View";
import {
  Stack,
  Box,
  ImageList,
  ImageListItem,
  Grid,
  Typography,
} from "@mui/material";

const HN = () => {
  return (
    <Stack spacing={5}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid xs={12} md={6} lg={6} style={{ textAlign: "center" }}>
          <Typography variant="h4">HOTEL SHERATON PRESIDENTE</Typography>
          <Typography variant="h5">SALÓN PRESIDENTE</Typography>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6519.356315457641!2d-89.24207928566695!3d13.69223789207516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f63302f3458a3d1%3A0x7d58cee957bb821f!2sHotel%20Sheraton%20Presidente!5e0!3m2!1ses!2sgt!4v1718998875924!5m2!1ses!2sgt"
            width="75%"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Grid>
        <Grid xs={12} md={6} lg={6} style={{ textAlign: "center" }}>
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/Sheraton_Presidente_San_Salvador_Hotel-San_Salvador-Aussenansicht-367379.jpg"
            style={{ width: "60%", loading: "lazy" }}
          />
        </Grid>
      </Grid>
      <ImageList cols={5}>
        <ImageListItem>
          <img
            src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/86803995.jpg`}
            alt="coretic-coreseb-img"
            style={{ width: "100%", objectFit: "cover" }}
            loading="lazy"
          />
        </ImageListItem>
        <ImageListItem>
          <img
            src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/sheraton-presidente-san-salvador-hotel-general-12341aad.jpg`}
            alt="coretic-coreseb-img"
            style={{ width: "100%", objectFit: "cover" }}
            loading="lazy"
          />
        </ImageListItem>
        <ImageListItem>
          <img
            src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/463637718.jpg`}
            alt="coretic-coreseb-img"
            style={{ width: "100%", objectFit: "cover" }}
            loading="lazy"
          />
        </ImageListItem>
        <ImageListItem>
          <img
            src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/Sheraton_Presidente_San_Salvador_Hotel-San_Salvador-Tagungsraum-3-367379.jpg`}
            alt="coretic-coreseb-img"
            style={{ width: "100%", objectFit: "cover" }}
            loading="lazy"
          />
        </ImageListItem>
        <ImageListItem>
          <img
            src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/expediav2-71831-1335c4-117275.jpg`}
            alt="coretic-coreseb-img"
            style={{ width: "100%", objectFit: "cover" }}
            loading="lazy"
          />
        </ImageListItem>
      </ImageList>
      <Box
        style={{}}
        sx={{
          width: "100%",
          height: { lg: "40vh", md: "20vh", xs: "5vh" },
          overflow: "hidden",
          position: "relative",
        }}
      >
        <video
          autoPlay
          muted
          playsInline
          loop
          style={{
            width: "100vw",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <source
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/1718925188.mp4"
            type="video/mp4"
          />
        </video>
      </Box>
      <View
        evt="81525e57-07be-4eaa-a365-3a1361080477"
        pais="EL SALVADOR"
      />
    </Stack>
  );
};

export default HN;
