import { React, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Typography, Grid, Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
const admin_service = require("../../helpers/admin_service");
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Patrocinio = (props) => {
  const [contador] = useState(0);
  const [evtGt, setEvtGt] = useState({});
  const [evtHn, setEvtHn] = useState({});
  const [evtSv, setEvtSv] = useState({});
  const [evtCr, setEvtCr] = useState({});

  const [cambios, setCambios] = useState(0);
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = 3;

  useEffect(() => {}, [cambios]);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setCambios(cambios + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setCambios(cambios + 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
    setCambios(cambios + 1);
  };

  useEffect(() => {
    admin_service
      .getData("/evento/view-by-hash/b6c43711-30cc-465b-bb2e-7e5e2f115ef3")
      .then((response_gt) => {
        admin_service
          .getData(
            "/evento/view-by-hash/fd4039e8-0bb4-4bca-9a7d-ab2b71f818ff"
          )
          .then((response_hn) => {
            admin_service
              .getData(
                "/evento/view-by-hash/81525e57-07be-4eaa-a365-3a1361080477"
              )
              .then((response_sv) => {
                setEvtGt(response_gt.data.response_database.result[0]);
                setEvtHn(response_hn.data.response_database.result[0]);
                setEvtSv(response_sv.data.response_database.result[0]);
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);
  return (
    <Box>
      <Stack spacing={4} style={{ padding: "5%" }}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid xs={12} md={12} lg={6}>
            <Stack spacing={3}>
              <Typography variant="h3" style={{ textAlign: "center" }}>
                Documento con Brief de Patrocinios
              </Typography>
              <Button
                startIcon={<PictureAsPdfIcon />}
                href="https://escuela-bancaria.s3.us-east-2.amazonaws.com/Brief+Congresos+C%26C+2024+(4).pdf"
                target="_blank"
              >
                Click para ver Brief de Patrocinios
              </Button>
            </Stack>
          </Grid>
          {evtGt &&
            evtHn &&
            evtSv &&
            evtGt.planos &&
            evtHn.planos &&
            evtSv.planos && (
              <Grid xs={12} md={12} lg={6}>
                <Stack spacing={5}>
                  <Typography variant="h3" style={{ textAlign: "center" }}>
                    PLANOS STAND
                  </Typography>
                  <AutoPlaySwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                    interval={5000}
                  >
                    {evtGt && evtGt.planos && (
                      <Box
                        alignItems="center"
                        justifyContent="center"
                        style={{ textAlign: "center", width: "100%" }}
                      >
                        <Grid
                          container
                          style={{
                            textAlign: "center",
                          }}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Grid
                            sx={{
                              width: { xs: "100%", md: "100%", lg: "50%" },
                            }}
                            item
                          >
                            <img
                              src={evtGt.planos}
                              alt={evtGt.hash}
                              style={{ width: "100%" }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                    {evtHn && evtHn.planos && (
                      <Box
                        alignItems="center"
                        justifyContent="center"
                        style={{ textAlign: "center", width: "100%" }}
                      >
                        <Grid
                          container
                          style={{
                            textAlign: "center",
                          }}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Grid
                            sx={{
                              width: { xs: "100%", md: "100%", lg: "50%" },
                            }}
                            item
                          >
                            {" "}
                            <img
                              src={evtHn.planos}
                              alt={evtHn.hash}
                              style={{ width: "100%" }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                    {evtSv && evtSv.planos && (
                      <Box
                        alignItems="center"
                        justifyContent="center"
                        style={{ textAlign: "center", width: "100%" }}
                      >
                        <Grid
                          container
                          style={{
                            textAlign: "center",
                          }}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Grid
                            sx={{
                              width: { xs: "100%", md: "100%", lg: "50%" },
                            }}
                            item
                          >
                            {" "}
                            <img
                              src={evtSv.planos}
                              alt={evtSv.hash}
                              style={{ width: "100%" }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </AutoPlaySwipeableViews>
                  <MobileStepper
                    style={{ width: "100%", backgroundColor: "transparent" }}
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                      <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                      >
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowLeft />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                      >
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowRight />
                        ) : (
                          <KeyboardArrowLeft />
                        )}
                      </Button>
                    }
                  />
                </Stack>
              </Grid>
            )}
        </Grid>
        <Typography variant="h3" style={{ textAlign: "center" }}>
          BRIEF PATROCINIOS
        </Typography>
        <Divider style={{ backgroundColor: "#292F3B", height: "5px" }} />
        <Typography
          variant="h4"
          style={{ color: "#1e3d52", textAlign: "center" }}
        >
          Diamante
          <br /> USD$ 14,800 (con gira) <br /> USD$ 6,700 (sin gira)
        </Typography>
        <Typography variant="h5" style={{ textAlign: "left" }}>
          <strong>Derecho a los siguientes beneficios:</strong>
        </Typography>
        <Typography
          variant="p"
          style={{ fontSize: "1.5em", textAlign: "justify" }}
        >
          <ul>
            <li>
              Logotipo de la empresa ubicado en posición adquirida de patrocinio
              en la página del evento, direccionado a su página web
            </li>
            <li>1 conferencia de 30 min + 10 min de preguntas y respuestas</li>
            <li>
              Reconocimiento en medios impresos y digitales (incluyendo
              revistas)
            </li>
            <li>
              GIRA CORETIC & CORESEB CENTROAMERICA (Honduras , Costa Rica y El
              Salvador)
            </li>
            <li>
              <strong>
                1 taller presencial de aplicación de soluciones (Ciberseguridad
                o transformación digital)
              </strong>
              <br /> Durante el tiempo del coffee break. <br />
              Cupo limitado*
              <br /> *Beneficio exclusivo para Patrocinio Diamante
            </li>
            <li>
              1 pauta pagada en redes sociales del conferencista designado (con
              mención de marca exclusiva)
            </li>
            <li>Pautas en redes sociales de EBG</li>
            <li>
              Mails masivos a nuestras bases de datos de CORETIC & CORESEB de
              años anteriores
            </li>
            <li>Mención de Patrocinio en la Aplicación de ABG</li>
            <li>Mails masivos en alianza con media partners</li>
            <li>Presencia de marca dentro del salón de conferencias</li>
            <li>
              Logotipo en Panel principal sobre pantalla del evento con mención
              del patrocinio
            </li>
            <li>Bases de datos de los participantes en el congreso</li>
            <li>Stand en el Área de Exposición de 3 x 2 mts</li>
            <li>
              Gafetes de staff para su personal para atención del stand y
              conferencista (incluye alimentos y parqueo)
            </li>
            <li>Participaciones de cortesía</li>
            <li>
              Mención especial y promocional de 30 segundos durante la
              conferencia, suministrada por el patrocinador
            </li>
          </ul>
        </Typography>
        <Divider style={{ backgroundColor: "#292F3B", height: "5px" }} />

        <Typography
          variant="h4"
          style={{ color: "#1e3d52", textAlign: "center" }}
        >
          Oro
          <br /> USD$ 12,000 (con gira) <br /> USD$ 5,000 (sin gira)
        </Typography>
        <Typography variant="h5" style={{ textAlign: "left" }}>
          <strong>Derecho a los siguientes beneficios:</strong>
        </Typography>
        <Typography
          variant="p"
          style={{ fontSize: "1.5em", textAlign: "justify" }}
        >
          <ul>
            <li>
              Logotipo de la empresa ubicado en posición adquirida de patrocinio
              en la página del evento, direccionado a su página web
            </li>
            <li>1 conferencia de 30 min + 10 min de preguntas y respuestas</li>
            <li>
              Reconocimiento en medios impresos y digitales (incluyendo
              revistas)
            </li>
            <li>
              GIRA CORETIC & CORESEB CENTROAMERICA (Honduras , Costa Rica y El
              Salvador)
            </li>

            <li>Pautas en redes sociales de EBG</li>
            <li>
              Mails masivos a nuestras bases de datos de CORETIC & CORESEB de
              años anteriores
            </li>
            <li>Mención de Patrocinio en la Aplicación de ABG</li>
            <li>Mails masivos en alianza con media partners</li>
            <li>Presencia de marca dentro del salón de conferencias</li>
            <li>Bases de datos de los participantes en el congreso</li>
            <li>Stand en el Área de Exposición de 3 x 2 mts</li>
            <li>
              Gafetes de staff para su personal para atención del stand y
              conferencista (incluye alimentos y parqueo)
            </li>
            <li>Participaciones de cortesía</li>
          </ul>
        </Typography>
        <Divider style={{ backgroundColor: "#292F3B", height: "5px" }} />
        <Typography
          variant="h4"
          style={{ color: "#1e3d52", textAlign: "center" }}
        >
          Plata
          <br /> USD$ 9,900 (con gira) <br /> USD$ 3,900 (sin gira)
        </Typography>
        <Typography variant="h5" style={{ textAlign: "left" }}>
          <strong>Derecho a los siguientes beneficios:</strong>
        </Typography>
        <Typography
          variant="p"
          style={{ fontSize: "1.5em", textAlign: "justify" }}
        >
          <ul>
            <li>
              Logotipo de la empresa ubicado en posición adquirida de patrocinio
              en la página del evento, direccionado a su página web
            </li>
            <li>
              Reconocimiento en medios impresos y digitales (incluyendo
              revistas)
            </li>
            <li>
              GIRA CORETIC & CORESEB CENTROAMERICA (Honduras , Costa Rica y El
              Salvador)
            </li>

            <li>Pautas en redes sociales de EBG</li>
            <li>
              Mails masivos a nuestras bases de datos de CORETIC & CORESEB de
              años anteriores
            </li>
            <li>Mención de Patrocinio en la Aplicación de ABG</li>
            <li>Mails masivos en alianza con media partners</li>
            <li>Presencia de marca dentro del salón de conferencias</li>
            <li>Bases de datos de los participantes en el congreso</li>
            <li>Stand en el Área de Exposición de 3 x 2 mts</li>
            <li>
              Gafetes de staff para su personal para atención del stand y
              conferencista (incluye alimentos y parqueo)
            </li>
            <li>Participaciones de cortesía</li>
          </ul>
        </Typography>
        <Divider style={{ backgroundColor: "#292F3B", height: "5px" }} />
      </Stack>
    </Box>
  );
};

export default Patrocinio;
