import React from "react";
import View from "../evento/View";
import {
  Stack,
  Box,
  ImageList,
  ImageListItem,
  Typography,
  Grid,
} from "@mui/material";

const GT = () => {
  return (
    <Stack spacing={5}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid xs={12} md={6} lg={6} style={{ textAlign: "center" }}>
          <Typography variant="h4">HOTEL BARCELÓ</Typography>
          <Typography variant="h5">Salón las Naciones</Typography>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.025167691274!2d-90.5215387242587!3d14.597641777147818!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a3da4d796741%3A0x67a03ec10722b685!2sBarcel%C3%B3%20Guatemala%20City!5e0!3m2!1ses!2sgt!4v1711994358472!5m2!1ses!2sgt"
            width="75%"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Grid>
        <Grid xs={12} md={6} lg={6} style={{ textAlign: "center" }}>
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/BGUA_VIEW_01.jpg"
            style={{ width: "60%", loading: "lazy" }}
          />
        </Grid>
      </Grid>
      <ImageList cols={5}>
        <ImageListItem>
          <img
            src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/5b1900b9-b7ac-4159-b489-0bb2ac1f03a1.jpg`}
            alt="corain-img"
            style={{ width: "100%", objectFit: "cover" }}
            loading="lazy"
          />
        </ImageListItem>
        <ImageListItem>
          <img
            src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/01733751_gp.jpg`}
            alt="corain-img"
            style={{ width: "100%", objectFit: "cover" }}
            loading="lazy"
          />
        </ImageListItem>
        <ImageListItem>
          <img
            src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/BGUA_WED_29.webp`}
            alt="corain-img"
            style={{ width: "100%", objectFit: "cover" }}
            loading="lazy"
          />
        </ImageListItem>
        <ImageListItem>
          <img
            src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/BGC%2B-%2BEL%2BDORADO%2BMONTAJE.jpg`}
            alt="corain-img"
            style={{ width: "100%", objectFit: "cover" }}
            loading="lazy"
          />
        </ImageListItem>
        <ImageListItem>
          <img
            src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/129852.jpg`}
            alt="corain-img"
            style={{ width: "100%", objectFit: "cover" }}
            loading="lazy"
          />
        </ImageListItem>
      </ImageList>
      <Box
        style={{}}
        sx={{
          width: "100%",
          height: { lg: "40vh", md: "20vh", xs: "5vh" },
          overflow: "hidden",
          position: "relative",
        }}
      >
        <video
          autoPlay
          muted
          playsInline
          loop
          style={{
            width: "100vw",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <source
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/1716490210.mp4"
            type="video/mp4"
          />
        </video>
      </Box>
      <View
        evt="b6c43711-30cc-465b-bb2e-7e5e2f115ef3"
        pais="GUATEMALA"
      />
    </Stack>
  );
};

export default GT;
