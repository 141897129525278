import React from "react";
import View from "../evento/View";
import {
  Box,
  Stack,
  ImageList,
  ImageListItem,
  Typography,
  Grid,
} from "@mui/material";

const HN = () => {
  return (
    <Stack spacing={5}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid xs={12} md={6} lg={6} style={{ textAlign: "center" }}>
          <Typography variant="h4">HOTEL PLAZA JUAN CARLOS</Typography>
          <Typography variant="h5"> SALÓN SORTILEGIO, HONDURAS.</Typography>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3869.5565036371263!2d-87.1992338267661!3d14.103337889061315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f6fa2c3c77775e1%3A0xce591363ce5c3dcf!2sHotel%20Plaza%20Juan%20Carlos!5e0!3m2!1ses!2sgt!4v1718998003833!5m2!1ses!2sgt"
            width="75%"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Grid>
        <Grid xs={12} md={6} lg={6} style={{ textAlign: "center" }}>
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/271507068.jpg"
            style={{ width: "60%", loading: "lazy" }}
          />
        </Grid>
      </Grid>
      <ImageList cols={5}>
        <ImageListItem>
          <img
            src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/fachada-del-hotel-vista.jpg`}
            alt="coretic-coreseb-img"
            style={{ width: "100%", objectFit: "cover" }}
            loading="lazy"
          />
        </ImageListItem>
        <ImageListItem>
          <img
            src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/recepcion2-mediun.webp`}
            alt="coretic-coreseb-img"
            style={{ width: "100%", objectFit: "cover" }}
            loading="lazy"
          />
        </ImageListItem>
        <ImageListItem>
          <img
            src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/images+-+2024-06-21T131409.443.jpg`}
            alt="coretic-coreseb-img"
            style={{ width: "100%", objectFit: "cover" }}
            loading="lazy"
          />
        </ImageListItem>
        <ImageListItem>
          <img
            src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/GMEZ1827-scaled.jpg`}
            alt="coretic-coreseb-img"
            style={{ width: "100%", objectFit: "cover" }}
            loading="lazy"
          />
        </ImageListItem>
        <ImageListItem>
          <img
            src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/467044988.jpg`}
            alt="coretic-coreseb-img"
            style={{ width: "100%", objectFit: "cover" }}
            loading="lazy"
          />
        </ImageListItem>
      </ImageList>
      <Box
        style={{}}
        sx={{
          width: "100%",
          height: { lg: "40vh", md: "20vh", xs: "5vh" },
          overflow: "hidden",
          position: "relative",
        }}
      >
        <video
          autoPlay
          muted
          playsInline
          loop
          style={{
            width: "100vw",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <source
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/1718925284.mp4"
            type="video/mp4"
          />
        </video>
      </Box>
      <View
        evt="fd4039e8-0bb4-4bca-9a7d-ab2b71f818ff"
        pais="HONDURAS"
      />
    </Stack>
  );
};

export default HN;
